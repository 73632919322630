import http from '~/utils/http'

export class JmPayApi {
  // @POST('/jav/jmf/getJmfPayList')
  // Future<List<PayChannel>> getJmfPayList(@Body() AmountParam param);
  static getJmfPayList(params: AmountParam, opt?: HttpOptions) {
    return http.post<Array<PayChannel>>({ ...opt, url: '/jav/jmf/getJmfPayList', body: params })
  }

  // @POST('/jav/jmf/confirmPayment')
  // Future<PayResult> confirmPayment(@Body() JmPayParam param);
  static confirmPayment(params: JmPayParam, opt?: HttpOptions) {
    return http.post<PayResult>({ ...opt, url: '/jav/jmf/confirmPayment', body: params })
  }
}
