<script setup lang="ts">
import { map } from 'rxjs/operators'
import { OpenVipApi } from '~/net/apis/open_vip_api'
import { JmPayApi } from '~/net/apis/jm_pay_api'
import { getBrowserFinger } from '~/utils/finger'
import empty_file from '~/assets/image/empty/empty_file@2x.png'
import icon_gold from '~/assets/image/icon/icon_gold@2x.png'

import icon_alipay from '~/assets/image/vip/icon_alipay@2x.png'
import icon_wechat from '~/assets/image/vip/icon_wechat@2x.png'
import icon_usdt from '~/assets/image/vip/icon_usdt@2x.png'
import icon_bank from '~/assets/image/vip/icon_bank@2x.png'
import icon_other from '~/assets/image/vip/icon_pay_other@2x.png'

const router = useRouter()
const onClickLeft = () => history.back()
const show = ref(false)
const showLoad = ref(false)
const _list = useObservable(from(OpenVipApi.coinList()).pipe(map(({ data }) => data)))
const _payList = ref<any[]>()
const usdtCard: any = { // 静态虚拟币
  type: 3,
  name: '虚拟币',
  icon: icon_usdt
}
const imgAry = [icon_alipay, icon_wechat, icon_bank, icon_usdt, icon_other]
function goRecharge() {
  router.push('/flowRecord')
}
function goService() {
  router.push('/service')
}
const buy = ref()
async function openBuy(item: Sku) {
  show.value = true
  showLoad.value = true
  buy.value = item
  console.log('>>>>price', item.price)
  await getJmfPayList(item.price).catch( e => {
    showLoad.value = false
  })
  setTimeout(() => {
    showLoad.value = false
  }, 800)
}

async function goBuy(item: any) {
  show.value = true
  showLoad.value = true
  await confirmPayment({ amount: `${buy?.value.price}`, payType: item.payType }).catch(e => {
    showLoad.value = false
  })
  setTimeout(() => {
    showLoad.value = false
  }, 800)
}
const activeNames = ref([])

async function confirmPayment(params: type) {
  const finger = await getBrowserFinger()
  const { data } = await JmPayApi.confirmPayment({ ...params, deviceNo: finger })
  if (data.frontLoadFlag === 0) {
    window.open(data.response)
  }
}

async function getJmfPayList(price?: number) {
  try {
    const { data } = await JmPayApi.getJmfPayList({ amount: price })
    data.push(usdtCard)
    _payList.value = data
  } catch (error) {}
}
</script>

<template>
  <div h-screen>
    <van-nav-bar title="充值金币" left-arrow @click-left="onClickLeft">
      <template #right>
        <button mr-2 ct-ffffff @click="goRecharge">充值记录</button>
      </template>
    </van-nav-bar>
    <div v-if="(_list ?? []).length > 0" overflow-y-auto class="mh">
      <div v-for="item in _list" :key="item.id" m-4 h-19 flex flex-col rounded-1 class="bg">
        <div flex flex-row justify-between>
          <div flex flex-row>
            <div relative ml-3 mt-2.5 h-14 w-14 flex items-center justify-center border-1 rounded-full bg-f1c77c>
              <img h-10 w-10 :src="icon_gold" />
              <div absolute left--2 top--2 h-4 p-1 pb-0.25 pt-0.25 text-center text-xs ct-ffffff class="bg2">
                加赠{{ item?.giftNum }}
              </div>
            </div>
            <div ml-2 mt-3 ct-563934>
              <div text-xs>金币</div>
              <div text-3xl font-black>
                {{ item.quantity }}
              </div>
            </div>
          </div>
          <div
            class="bg3"
            mr-3
            mt-5.5
            h-8
            min-w-28
            flex
            items-center
            justify-center
            rounded-4.5
            p-2
            text-center
            text-base
            ct-ffffff
            @click="openBuy(item)"
          >
            购买￥{{ item.price }}
          </div>
        </div>
      </div>
      <div fixed bottom-0 h-40 w-full flex items-center justify-center bg-111425>
        <div h-23 w-65>
          <P mb-5 text-center text-sm ct-e8e8e8>
            充值遇到问题？请 <span border-b-1 border-b-orange ct-ff932f @click="goService">联系客服</span>
          </P>
          <P text-xs ct-bdbec1> 1、出现风险提示请不要担心，重新支付即可。 </P>
          <P text-xs ct-bdbec1> 2、当前支付通道无法付款，请选择其他方式。 </P>
          <P text-xs ct-bdbec1> 3、付款如遇到其他问题，可咨询在线客服处理。 </P>
        </div>
      </div>
      <van-popup v-model:show="show" closeable position="bottom" h-127>
        <div mt-4 text-center text-xl ct-e8e8e8>请选择充值方式</div>
        <div border-b-1 border-b-gray-500 pb-2 pt-2 text-center text-xs ct-e8e8e8>
          已选择 {{ buy.quantity }} 金币 <span ct-FD5B03>￥{{ buy.price }}</span>
        </div>
        <div v-if="_payList && _payList.length > 0" mt-8>
          <van-collapse v-model="activeNames" :border="false">
            <div v-for="(item, index) in _payList" :key="index">
              <van-collapse-item v-if="item.type !== 3" :border="false" :name="index">
                <template #title>
                  <div h-14 flex flex-row items-center>
                    <img ml-2 mr-3 h-10 w-10 :src="imgAry[item?.type ?? 0]" />
                    {{ item.name }}
                  </div>
                </template>
                <div
                  v-for="(item1, index1) in item.subPayTypeList"
                  :key="index1"
                  mb-1
                  h-13
                  flex
                  items-center
                  justify-between
                  bg-1e223a
                >
                  <span ml-2 ct-e8e8e8>{{ item1.payTypeName }}</span>
                  <div
                    class="bg4"
                    mr-3
                    h-6
                    w-16
                    flex
                    items-center
                    justify-center
                    rounded-4.5
                    p-2
                    text-center
                    text-base
                    ct-ffffff
                    @click="goBuy(item1)"
                  >
                    购买
                  </div>
                </div>
              </van-collapse-item>
              <van-collapse-item v-else :border="false" :name="index">
                <template #title>
                  <div h-14 flex flex-row items-center>
                    <img ml-2 mr-3 h-10 w-10 :src="item.icon" />
                    {{ item.name }}
                  </div>
                </template>
                <div h-40 w-full flex items-center justify-center>
                  <div h-23 w-65 ct-bdbec1>
                    <P mb-3 text-sm> 专属客服为您提供虚拟币直充上分服务 </P>
                    <P text-xs> 1、联系客服提供会员ID、需要充值的金币数 </P>
                    <P text-xs> 2、根据客服提供的虚拟币收款地址及金额转账 </P>
                    <P text-xs> 3、向客服提供转账成功截图 </P>
                    <P text-xs> 4、客服上分到账 </P>
                  </div>
                </div>
                <div mt-4 flex justify-center>
                  <div h-11 w-88 rounded text-center leading-11 ct-ffffff class="bg4" @click="goService">联系客服</div>
                </div>
              </van-collapse-item>
            </div>
          </van-collapse>
          <div h-40 w-full flex items-center justify-center>
            <div h-23 w-65>
              <P mb-5 text-center text-sm ct-e8e8e8>
                充值遇到问题？请 <span border-b-1 border-b-orange ct-ff932f @click="goService">联系客服</span>
              </P>
              <P text-xs ct-bdbec1> 1、出现风险提示请不要担心，重新支付即可。 </P>
              <P text-xs ct-bdbec1> 2、当前支付通道无法付款，请选择其他方式。 </P>
              <P text-xs ct-bdbec1> 3、付款如遇到其他问题，可咨询在线客服处理。 </P>
            </div>
          </div>
        </div>
        <div v-else h-100 w-full flex items-center justify-center ct-828ab1>暂无可用支付渠道，请稍后再试</div>
      </van-popup>
      <van-overlay :show="showLoad" style="z-index: 9999" @click="showLoad = false">
        <div h-screen flex items-center justify-center @click.stop>
          <div flex items-center justify-center>
            <van-loading vertical h-25 w-25 bg-3b405a ct-ffffff size="24px" />
          </div>
        </div>
      </van-overlay>
    </div>
    <div v-else h-2xl flex flex-col items-center justify-center px-4>
      <img h-33 w-50 :src="empty_file" />
      <div mt-3 text-xs ct-ffffff>暂时没有数据</div>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
.bg {
  background: linear-gradient(90deg, #efbc77 0%, #efcf9a 100%);
}
:deep(.van-action-sheet__item) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__cancel) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__gap) {
  background: #414660;
}
:deep(.van-action-sheet__item:hover) {
  color: #fd5f03;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
.bg2 {
  background: linear-gradient(90deg, #eb3349 0%, #f45c43 100%);
}
.bg3 {
  background: linear-gradient(90deg, #fe9849 0.84%, #ff3e4e 99.19%, #fe8d21 100%);
}
.bg4 {
  background: linear-gradient(130.94deg, #fd9403 16.87%, #fd5b03 89.42%);
  padding:0 6px;
}
.mh {
  max-height: calc(100% - 190px);
}
:deep(.van-popup) {
  background: #242943;
}
:deep(.van-cell) {
  font-size: 16px;
  background: #242943;
  color: #e8e8e8;
  padding: 0 20px;
  align-items: center;
}
:deep(.van-cell:after) {
  border: none;
}
:deep(.van-cell__right-icon) {
  color: #ffffff;
}
:deep(.van-cell-group--inset) {
  border-radius: unset;
}
:deep(.van-field__label) {
  color: #e8e8e8;
}
:deep(.van-cell__value) {
  color: #5c5e69;
}
:deep(.van-collapse-item__content) {
  background: #242943;
  border: none;
}
:deep(.van-loading--vertical) {
  justify-content: center;
}
</style>
